import React, { useEffect, useState, useContext } from 'react'
import M from 'materialize-css';
import { ModalCourse } from './ModalCourse';
import { ModalEdit } from './ModalEdit';
import CourseAdmin from '../../components/CourseAdmin';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../auth/AuthContext';
import { fetchCourses, verifyUser } from '../../functions/firebasedb';



const Courses = () => {

    const [ courses, setCourses ] = useState({});
    const [ edit, setEdit ] = useState({});
    const [ id, setId ] = useState("");

    const { user } = useContext(AuthContext)
    const history = useHistory()

    const setInfo = ( xcurso, xid) => {
        setEdit(xcurso);
        setId(xid);
    }

    useEffect(() => {
        var elems3 = document.querySelectorAll('.modal');
        var instances3 = M.Modal.init(elems3, {});
        fetchCourses( setCourses );
        verifyUser( history, user.email)
        return () => { }
    }, [])

    return (
        <div>
            <div className="row">
                <div className="col m6">
                    <h6 className="card-title">Cursos</h6>
                </div>
                <div className="col m6 right-align">
                    <a className="btn modal-trigger" href="#modalNewCourse">Nuevo curso </a>
                </div>
            </div>
            <div className="market_grid">
                { courses
                && ( Object.entries(courses).map( course => (
                    <CourseAdmin course={course[1]} key={course[0]} admin={true} id={course[0]} setInfo={setInfo} />
                )))
                }
            </div>
            <ModalCourse />
            <ModalEdit edit={edit} id={id} />
        </div>
    )
}

export default Courses;