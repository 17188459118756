import React, { useState, useContext } from 'react'

import { AuthContext } from '../../auth/AuthContext';
import { SignIn } from '../../functions/authentication';

import { Link } from 'react-router-dom';

const Login = ({history}) => {

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const { dispatch } = useContext(AuthContext)

    const handleSubmit = e => SignIn(e, email, password, history, dispatch);


    return (
        <div className="container login">
            <div className="row">
                <div className="show-on-large col s12 m5 ">
                    <div className="side_content">
                        <div>
                            <h5>Iniciar sesión</h5>
                            <p>Bienvenido</p>
                            <span>¿No tienes cuenta?,<Link to="/register"><strong className="link_outside"> regístrate aquí.</strong></Link></span>
                        </div>
                    </div>
                </div>
                <div className="col s12 m7 form-content">
                    <div>
                        <div className="card-content">
                            <div className="center-align">
                                <img src="https://unives.mx/wp-content/uploads/2021/01/logounives.png" />
                            </div>
                            <h5>Iniciar sesión</h5>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Correo electrónico</label>
                                    <input 
                                        type="email" 
                                        id="email" 
                                        placeholder="example@unives.mx"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Contraseña</label>
                                    <input 
                                        type="password" 
                                        id="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        />
                                </div>
                                <div className="form-group center-align">
                                    <button className="btn btn-primary light-blue darken-4 waves-effect waves-light">Iniciar sesión</button>
                                    <br/><br/>
                                    <span>¿No tienes cuenta?,<Link to="/register"><strong className="link_outside"> regístrate aquí.</strong></Link></span><br />
                                    <span><Link to="/forgot-password"><strong className="link_outside">Olvidé la contraseña</strong></Link></span>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Login;