import React, { useState } from 'react'
import { addCourse } from '../../functions/firebasedb'

export const ModalCourse = () => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [landing, setLanding] = useState("")


    const handleSubmit = e => {
        e.preventDefault();
        addCourse(name, description, landing);
        document.getElementById('modal-close').click();
    }
    return (
        <div>

            <div id="modalNewCourse" className="modal">
                <form onSubmit={handleSubmit}>
                    <div className="modal-content">
                        <h4>Agregar nuevo curso</h4>
                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    placeholder="Nombre..."
                                    id="name" type="text"
                                    className="validate"
                                    onChange={e => setName(e.target.value)}
                                />
                                <label htmlFor="name" className="active">Nombre del curso</label>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    placeholder="Descripción..."
                                    id="description"
                                    type="text"
                                    className="validate"
                                    onChange={e => setDescription(e.target.value)}
                                />
                                <label htmlFor="description" className="active">Descripción corta</label>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    placeholder="https://unives.mx/promocion-horizontal-docentes/"
                                    id="landing"
                                    type="text"
                                    className="validate"
                                    onChange={e => setLanding(e.target.value)}
                                />
                                <label htmlFor="landing" className="active">Liga de Landing</label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a className="btn mr-2 modal-close" id="modal-close" type="button">Cancelar</a>
                        <button className="btn blue darken-3" type="submit">Guardar curso</button>
                    </div>
                </form>
            </div>

        </div>
    )
}
