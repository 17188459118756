import { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';
import { addResults, getContact } from '../functions/firebasedb';

const ContactDetails = () => {

    const { user } = useContext(AuthContext);

    const { contactId } = useParams();
    const [contact, setContact] = useState({});
    const [resultado, setResultado] = useState("");
    const [venta, setVenta] = useState("1");

    const handleSubmit = (e) => {
        e.preventDefault();
        addResults(resultado, venta, contactId );
    }

    useEffect(() => {
        getContact(contactId, setContact);
    }, [])

    return (
        <>
            <h6 className="card-title">
                {
                    user.info.user.admin == 1
                    ? (<Link to="/admin/contacts">Contactos</Link>) 
                    : (<Link to="/contacts">Contactos</Link>) 
                }
                
                <i className="material-icons">keyboard_arrow_right</i> 
                Detalles del contacto
                </h6>
            <div className="card">
                <div className="card-content">
                    <form onSubmit= { handleSubmit }>
                        <div className="row">
                            <div className="form-group col s12">
                                <label htmlFor="username">Nombre Completo</label>
                                <input type="text" id="username" className="form-control" defaultValue={contact.username} readOnly />
                            </div>
                            <div className="form-group col m6">
                                <label htmlFor="email">Email</label>
                                    <input type="text" id="email" className="form-control" defaultValue={contact.email} readOnly />
                            </div>
                            <div className="form-group col m6">
                                <label htmlFor="phone">Teléfono</label>
                                <a href={`tel:${contact.phone}`}>
                                    <input type="text" id="phone" className="form-control" defaultValue={contact.phone} readOnly />
                                </a>
                            </div>
                            <div className="form-group col s12">
                                <label htmlFor="programa">Programa de interés</label>
                                <input type="text" id="programa" className="form-control" defaultValue={contact.programa} readOnly />
                            </div>
                            <div className="form-group col s12">
                                <label htmlFor="registro">Fecha de registro</label>
                                <input type="text" id="registro" className="form-control" defaultValue={contact.registerAt} readOnly />
                            </div>
                            {
                                user.info.user.affcode === contact.affcode
                                ? (<>
                                <div className="form-group col s12">
                                    <label htmlFor="resultado">Ingresar Resultado</label>
                                    <textarea
                                        type="text"
                                        id="resultado"
                                        className="materialize-textarea"
                                        value={contact ? contact.resultado : resultado}
                                        onChange={e => setResultado(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col s12">
                                    <label htmlFor="venta">Marcar como:</label>
                                    <select name="venta" id="venta" onChange={e => setVenta(e.target.value)} value={venta}>
                                        <option value="1">Venta ganada</option>
                                        <option value="2">Venta perdida</option>
                                    </select>
                                </div>
                                <div className="col s12">
                                    <br />
                                    <button className="btn btn-primary">Guardar resultado</button>
                                </div>
                                    </> )
                                : (
                                    <></>
                                )
                            }
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default ContactDetails;
