import React from 'react'

export const Lesson = ({ lesson, setSelected }) => {
    return (
        <li className="collection-item" onClick={ e => setSelected(lesson)}>
            <div>
                { lesson.title }
            <a href="#!" className="secondary-content">
                    <i className="material-icons">play_circle_outline</i>
                </a>
            </div>
        </li>
    )
}
