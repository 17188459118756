import React, { useContext, useState, useEffect } from 'react'
import avatar from '../assets/img/avatar.jpg'
import { AuthContext } from '../auth/AuthContext'
import { fetchUser  } from '../functions/authentication';
import banks from '../constants/banks.json'
import { changeAvatar, updateProfile } from '../functions/firebasedb';

const Profile = () => {

    const { user } = useContext(AuthContext);
    const [ userx, setUserx ] = useState({});

    const [ name, setName ] = useState("")
    const [ phone, setPhone ] = useState("")
    const [ bankNumber, setBankNumber ] = useState("")
    const [bankName, setBankName] = useState("")
    const [ state, setState ] = useState("")

    const handleSubmit = e => {
        e.preventDefault();
        updateProfile( userx.id, name, phone, bankNumber, state, bankName )
    };

    const handleClickPhoto = () => {
        document.getElementById('avatar_file').click();
    }

    const handleSelect = e => changeAvatar( e, userx.id );
    

    useEffect(() => {
        fetchUser( setUserx, user.email )
    }, [])
    
    useEffect(() => {
        if(userx.user){
            setName(userx.user.name)
            setPhone(userx.user.phone)
            if(userx.user.state){
                setBankNumber(userx.user.bank);
                setState(userx.user.state);
            }
        }
    },[userx])

    return (
        <div className="row profile_content">
            <div className="col s12">
                <form onSubmit={ handleSubmit }>
                <h6 className="card-title">Mi perfil</h6>
                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                <div className="avatar_content">
                                    {
                                        userx.user
                                        ?   <div className="avatar_div">
                                                <img src={userx.user.avatar} alt="avatar" className="depth" />
                                                <div>
                                                    <span onClick={ handleClickPhoto }>Cambiar imagen</span> 
                                                </div>
                                                <input type="file" name="avatar" id="avatar_file" onChange={ handleSelect } hidden/>
                                            </div>
                                        : <img src={avatar} alt="avatar" className="depth" />
                                    }
                                    
                                </div>
                            </div>
                            <div className="col s12">
                                <br/>
                            </div>
                            <div className="input-field col m6 s12">
                                <input 
                                    placeholder="Nombre completo" 
                                    id="first_name" 
                                    type="text" 
                                    className="validate"
                                    value={name}
                                    onChange={ e => setName(e.target.value) }
                                    />
                                <label htmlFor="first_name" className="active">Nombre completo</label>
                            </div>
                            <div className="input-field col m6 s12">
                                <input 
                                    placeholder="Correo electrónico" 
                                    id="email" type="email" 
                                    className="validate" 
                                    readOnly
                                    disabled
                                    defaultValue={user.email}
                                    />
                                <label htmlFor="email" className="active">Correo electrónico</label>
                            </div>
                            <div className="input-field col m6 s12">
                                <input 
                                    placeholder="Teléfono" 
                                    id="phone" type="text" 
                                    className="validate"
                                    value={phone}
                                    onChange={e => setPhone(e.target.value) }
                                    />
                                <label htmlFor="phone" className="active">Teléfono</label>
                            </div>
                            <div className="input-field col m6 s12">
                                <input 
                                    placeholder="Código de afiliado" 
                                    id="affcode" 
                                    type="text" 
                                    readOnly
                                    disabled
                                    className="validate"
                                    defaultValue={ userx.user ? userx.user.affcode : ""}
                                    />
                                <label htmlFor="affcode" className="active">Código de afiliado</label>
                            </div>
                            <div className="input-field col m6 s12">
                                <input 
                                    type="text"
                                     id="state"
                                     onChange={e => setState(e.target.value)}
                                     value={ state }
                                     />
                                <label htmlFor="state" className="active">Estado/Provincia</label>
                            </div>
                            <div className="col m12">
                                <small>INFORMACIÓN BANCARIA</small>
                                <br/>
                                <br/>
                            </div>
                            <div className="input-field col m6 s12">
                                <label htmlFor="bank" className="active">Entidad bancaria</label>
                                <select name="bank" id="bank" onChange={ e => setBankName(e.target.value)}>
                                    { banks.map((bankE, key) => {
                                        return(
                                            <option key={ key } selected={userx.user && (userx.user.bankName == bankE ? "selected" : "") } value={bankE}>{bankE}</option>)}
                                        )
                                    }
                                </select>
                            </div>
                            <div className="input-field col m6 s12">
                                <input 
                                    type="text" 
                                    placeholder="123 123 4567" 
                                    id="bankNumber"
                                    onChange={ e => setBankNumber(e.target.value)}
                                    value={ bankNumber }
                                    />
                                <label htmlFor="bank" className="active">Cuenta bancaria</label>
                            </div>
                            <div className="col s12">
                                <button className="btn blue darken-4 waves-effect" type="submit">Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    )
}

export default Profile;