import { getAfiliadoPor, updateContact, updateStatusClientifyAfiliado } from '../functions/firebasedb';
import M from 'materialize-css';
import { API_KEY } from '../constants/clientify';

export const sendToClientify = async( contact, resultado, owner, id ) => {
    console.log('contact', contact);
    console.log('resultado ', resultado);
    console.log('owner ', owner);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token d4029a908e7a6bbf43f3148a86b6d23c6d8f662b");
    myHeaders.append("Content-Type", "application/json");

    const affcode = contact.affcode || "N/A";

    var datos = JSON.stringify({
        "first_name": contact.username,
        "email": contact.email,
        "phone": contact.phone,
        "status": "cold-lead",
        "contact_source": "realtime",
        "addresses": [],
        "custom_fields": [
            { field: 'PROSPECTOS: Programa de interés', value: contact.programa},
            { field: 'programa-interes-facebook', value: contact.programa},
            { field: 'affcode', value: affcode}
        ],
        "wall_entries": [
            {
                "user": owner,
                "type": "call",
                "created" : new Date(),
                "extra" : resultado
            }
        ],
        "description": "Contacto creado desde Realtime",
        "message": resultado,
        "summary": resultado,
        "owner": owner
    });

    var reqOptions = {
        method: 'POST',
        headers: myHeaders,
        body: datos,
        redirect: 'follow'
    };
    if(resultado){
        await fetch('https://api.clientify.net/v1/contacts/', reqOptions)
            .then(res => res.json())
            .then(res => {
                console.log('ressss : ', res);
                console.log('url   ', res.url);
                M.toast({ html: 'Enviado a Clientify'});

                if( res.id){
                    //updateContact( id, res.id, owner);
                }

            }).catch(err => {
                console.log('Contact cannot created, error:',err);
                M.toast({ html: 'No se pudo crear el contacto.'})
            })
    }else{
        M.toast({ html: 'Escribe el resultado de la llamada'});
    }
}

export const sendAfiliadoToClientify = async () => {
    console.log(API_KEY);
    
    var headers = new Headers();
    headers.append("Authorization", `Token ${API_KEY}`);
    headers.append("Content-Type", "application/json");

    var reqOptions = {
        method: 'GET',
        headers: headers,
    }
    await fetch('https://api.clientify.net/v1/contacts/', reqOptions)
        .then(res => res.json())
        .then(res => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        })
}

export const sendTo = async( user, id ) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    const afiliado_por = user.affiliateBy ? await getAfiliadoPor( user.affiliateBy ): "N/A";

    var datos = JSON.stringify({
        "first_name": user.name,
        "email": user.email,
        "phone": user.phone,
        "status": "cold-lead",
        "contact_source": "afiliados",
        "addresses": [{
            "state": user.state || "N/A"
        }],
        "custom_fields": [
            { field: 'afiliado_por', value:afiliado_por }
        ],
        "description": "Afiliado creado automáticamente",
        "message": `Afiliado creado: ${new Date()}`,
        "summary": `Afiliado creado: ${new Date()}`,
        "owner": "danielmkt@unives.mx",
        "tags": ["afiliados-unives"],
        "created": new Date(),
        "picture_url": user.avatar,
    });

    var reqOptions = {
        method: 'POST',
        headers: myHeaders,
        body: datos,
        redirect: 'follow'
    };
    if(user){
        await fetch('https://api.clientify.net/v1/contacts/', reqOptions)
            .then(res => res.json())
            .then(res => {
                console.log('url   ', res.url);
                
                if( res.id ){
                    // M.toast({ html: 'Enviado a Clientify'});
                    updateStatusClientifyAfiliado(id, `https://clientify.net/contacts/${res.id}/`);
                }

            }).catch(err => {
                console.log('Contact cannot created, error:',err);
                M.toast({ html: 'No se pudo crear el contacto.'})
            })
    }
}