import React, { useEffect } from 'react'
import { ModalLinks } from './Fragments/ModalLinks'
import M from 'materialize-css';

const Course = ({ course, admin=false, id, affcode}) => {

  useEffect(() => {
    var elems2 = document.querySelectorAll('.dropdown-trigger');
    var instances2 = M.Dropdown.init(elems2, {});
    var elems3 = document.querySelectorAll('.modal');
    var instances3 = M.Modal.init(elems3, {});
    return () => {}
  }, [])

    return (
        <div className="course_card">
            <div className="card">
              <div className="card-image">
                <img src={course.photo}/>
                <span className="card-title"></span>
              </div>
              <div className="card-content">
                <h6 className="card-title">{ course.name } 
                </h6>
                <p>{ course.description}</p>
              </div>
              <div className="card-action">
                <ModalLinks id={id} landing={course.landing} affcode={affcode}/>
                <a href={`#dpl-${id}`} className="btn modal-trigger">Ver links</a>
              </div>
            </div>
        </div>
    )
}

export default Course;