import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import { getAfiliadoInfo } from '../../functions/firebasedb';
import { removeContact } from '../../functions/firebasedb';

const ContactItem = ({ contact, contactId, isAdmin = false }) => {

    const [afiliado, setAfiliado] = useState({})

    const fetchAfiliadoName = async () => {

        let response = await getAfiliadoInfo(contact.affcode);
        setAfiliado(response);
    }

    useEffect(() => {
        getAfiliadoInfo(contact.affcode, setAfiliado);
    }, [])

    useEffect(() => {
        var elems = document.querySelectorAll('.tooltipped');
        var instances = M.Tooltip.init(elems, {});
        return () => { }
    }, [])

    return (
        <tr>
            <td>
                {
                    contact.venta
                        ? (<Link to={`/contacts/${contactId}/details/`} className="tooltipped" data-position="bottom" data-tooltip={(contact.registerAt).split('GMT')[0]}>{contact.username}</Link>)
                        : (<strong>
                            <Link to={`/contacts/${contactId}/details/`} className="tooltipped" data-position="bottom" data-tooltip={(contact.registerAt).split('GMT')[0]}>{contact.username}</Link>
                        </strong>)
                }

            </td>
            <td>
                <a href="mailto:ti@unives.mx">{contact.email}</a> <br />
                <a href="tel:9515803048">{contact.phone}</a>
            </td>
            <td>
                {(contact.registerAt).split('GMT')[0]}
            </td>
            <td>
                {contact.programa}
            </td>
            {
                isAdmin
                    ? (
                        <td>
                            <strong>
                            <Link to={`/afiliados/${afiliado.id}/`}>
                                {afiliado.name}
                            </Link>
                            </strong>
                        </td>
                    )
                    : (<></>)
            }
            {
                isAdmin 
                ? (
                    <td>
                        <button 
                            className="btn-flat remove_btn_contact"
                            onClick={e => removeContact(contactId)}
                            >
                                <i className="material-icons red dark-300">delete</i>
                            </button>
                    </td>
                )
                : (
                    <></>
                )
            }
        </tr>
    )
}

export default ContactItem;
