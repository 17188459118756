import React from 'react'
import { Counter } from '../Fragments/Counter';
import { RedCounter } from '../Fragments/RedCounter';

const Activity = ({ affcode }) => {
    return (
        <div className="row">
            <br/>
            <div className="col m6 s12 l4">
                <div className="act-box">
                    <h5>
                        <RedCounter affcode={affcode}/>
                    </h5>
                    <div className="text">
                        <p>Red afiliados</p>
                    </div>
                </div>
            </div>
            <div className="col m6 s12 l4">
                <div className="act-box">
                    <h5>
                        { affcode && <Counter affcode={affcode}/>}
                        
                    </h5>
                    <div className="text">
                        <p>Prospectos generados</p>
                    </div>
                </div>
            </div>
            <div className="col m6 s12 l4">
                <div className="act-box">
                    <h5>
                        0
                    </h5>
                    <div className="text">
                        <p>Ventas cerradas</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activity;