import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { forgotPassword } from '../functions/authentication';

const ForgotPassword = () => {

    const [email, setEmail] = useState("")

    const handleSubmit = e => forgotPassword(e, email, setEmail);

    return (
        <div className="container login">
            <div className="row">
                <div className="show-on-large col s12 m5">
                    <div className="side_content">
                        <div>
                            <h5>Recuperar contraseña</h5>
                            <p>Bienvenido</p>
                            <span>¿No tienes cuenta?,<Link to="/register"><strong className="link_outside"> regístrate aquí.</strong></Link></span>
                        </div>
                    </div>
                </div>
                <div className="col s12 m7 form-content">
                    <div>
                        <div className="card-content">
                            <div className="center-align">
                                <img src="https://unives.mx/wp-content/uploads/2021/01/logounives.png" />
                            </div>
                            <h6>Recuperar contraseña</h6>
                            <form onSubmit={ handleSubmit }>
                                <div className="form-group">
                                    <label htmlFor="email">Ingresa tu email</label>
                                    <input 
                                        type="email" 
                                        id="email"
                                        placeholder="example@unives.mx"
                                        value={ email }
                                        onChange={e => setEmail(e.target.value)}
                                         />
                                </div>
                                <div className="form-group">
                                    <button 
                                        type="submit" 
                                        className="btn btn-primary blue darken-2"
                                        >Enviar link para reestablecer</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;