import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import ContactItem from '../../components/Fragments/ContactItem';
import { getAllContacts, getMyContacts } from '../../functions/firebasedb';


const ContactsAdmin = () => {

    const [contacts, setContacts] = useState([]);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        
        getAllContacts(setContacts);
    }, [])

    return (
        <>
            <h6 className="card-title">Contactos</h6>
            <div className="card card">
                <div className="card-content">
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Contacto</th>
                                <th>Fecha</th>
                                <th>Programa Educ.</th>
                                <th>Afiliado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { contacts && Object.entries(contacts).map(([id, contact]) => {
                                if(contact.affcode !== "N/A"){
                                    return (
                                        <ContactItem key={id} contact={contact} contactId={id} isAdmin={true} />
                                    )
                                }
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}


export default ContactsAdmin;