import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Lesson } from '../components/Fragments/Lesson';
import { Video } from '../components/Fragments/Video';

import { fetchLessons, fetchFirstLesson } from '../functions/firebasedb';

const Academy = () => {

    const [ lessons, setLessons ] = useState({});
    const [ selected, setSelected ] = useState({});

    useEffect(() => {

        fetchLessons( setLessons );
        fetchFirstLesson( setSelected )
        return ()=>{};
    },[]);

    useEffect(() => {
        if(lessons.length> 0){
            const [ key1, value1] = lessons;
            console.log(key1, value1);
        }
    },[lessons])

    return (
        <>
                <h6 className="card-title">Academia</h6>
            <div className="card">
                <div className="card-content">
                    <div className="row">
                        <div className="col m12 s12">
                            <h5 className="card-title">{ selected.title }</h5>
                            {
                                selected.link ? <Video link={`${selected.link}`}/> : <span>No video</span>   
                            }
                        </div>
                        <div className="col m6 s12">
                            <h6>Descripción</h6>
                            <p>{ selected.description }</p>
                        </div>
                        <div className="col m6 s12">
                            <h6>Contenido</h6>
                            <ul className="collection with-header">
                                {
                                    lessons
                                    ? ( Object.entries(lessons).map(([id, data]) => <Lesson key={id} lesson={ data } setSelected={setSelected}/>))
                                    : <h5>No se encontraron lecciones</h5>
                                }
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Academy;