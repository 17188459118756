import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const Firebase = firebase.initializeApp({
    apiKey: "AIzaSyDxq6B2eQqAdqiuWGOhj6GzBRfEtvjZRmA",
    authDomain: "rt-form.firebaseapp.com",
    databaseURL: "https://rt-form.firebaseio.com",
    projectId: "rt-form",
    storageBucket: "rt-form.appspot.com",
    messagingSenderId: "394927372574",
    appId: "1:394927372574:web:14852bac68a0dacc95b10f",
    measurementId: "G-YLY00CNL5E"
  });

export default firebase;