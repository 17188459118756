import React, { useState } from 'react'
import banks from '../../constants/banks.json'
import { sendTo } from '../../functions/clientify';

const Info = ({ user, id }) => {

    const handleSend = () => sendTo(user, id );

    return (
        <div>
            <br/>
            <div className="info-controls right-align">
                {
                    user.clientify
                    ? <a className="btn cyan darken-1" href={user.clientify} target="_blank">Ver en Clientify</a>
                    : <button className="btn cyan darken-1 mr-2" onClick={handleSend}>Enviar a Clientify</button>
                }
                
                <br/><br/>
            </div>
            <div className="row">
                <div className="col m6 s12 input-field">
                    <input type="text" id="name" value={ user.name } readOnly/>
                    <label className="active" htmlFor="name">Nombre completo</label>
                </div>
                <div className="col m6 s12 input-field">
                    <input type="email" id="email" readOnly value={ user.email } readOnly/>
                    <label className="active" htmlFor="email">Correo electrónico</label>
                </div>
                <div className="col m6 s12 input-field">
                    <input type="text" id="phone" value={ user.phone } readOnly/>
                    <label className="active" htmlFor="phone">Teléfono</label>
                </div>
                <div className="col m6 s12 input-field">
                    <input type="text" id="affcode" value={user.affcode} readOnly/>
                    <label className="active" htmlFor="affcode">Código de afiliado</label>
                </div>
                <div className="col m6 s12 input-field">
                    <input type="text" id="state" value={user.state} readOnly/>
                    <label className="active" htmlFor="state">Estado/Provincia</label>
                </div>
                <div className="col s12">
                    <small>INFORMACIÓN BANCARIA</small>
                </div>
                <div className="col m6 s12 input-field">
                    <select name="bankName" id="bankName" readOnly>
                    { banks.map((bankE, key) => {
                        return (
                            <option key={ key } selected={ user.bankName == bankE ? "selected": ""} value={bankE}>
                                { bankE}
                            </option>
                        )
                    })}
                    </select>
                </div>
                <div className="col m6 s12 input-field">
                    <input type="text" id="bankNumber"  value={user.bank} readOnly/>
                    <label htmlFor="bankNumber" className="active">Cuenta bancaria</label>
                </div>
            </div>
        </div>
    )
}

export default Info;