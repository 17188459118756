import React from 'react'
import { changeCoursePhoto } from '../../functions/firebasedb';

export const ImageCourse = ({ image, id }) => {

    const handleClick = () => {
        document.getElementById(`file-${id}`).click()
    }

    const handleChange = e => changeCoursePhoto(e, id)

    return (
        <div className="card-image course" onClick={ handleClick }>
            <div className="overlay">
                <img src={ image}/>
                <span className="label">Cambiar imagen</span>
            </div>
            <input type="file" name="image" id={`file-${id}`} hidden onChange={ handleChange }/>
        </div>
    )
}
