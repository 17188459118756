import React, {useContext, useEffect, useState} from 'react';
import M from 'materialize-css';

import Red from '../../components/Tabs/Red';
import Activity from '../../components/Tabs/Activity';
import Info from '../../components/Tabs/Info';
import { AuthContext } from '../../auth/AuthContext';
import { Link, useHistory, useParams } from 'react-router-dom';
import { fetchAfiliado, getAfiliadoPor, verifyUser } from '../../functions/firebasedb';

const AfiliadoDetails = () => {

    const { user } = useContext(AuthContext);
    const history = useHistory();
    const [afiliado, setAfiliado] = useState({})
    const { id } = useParams();

    useEffect(()=>{
        verifyUser(history, user.email)
        var el = document.querySelector('.tabs');
        var instance = M.Tabs.init(el, {})
        fetchAfiliado(id, setAfiliado);
    },[])
    useEffect(() => {
        if(afiliado){
            getAfiliadoPor(afiliado.affiliateBy)
        }
        return () => {}
    }, [afiliado])

    return (
        <div>
            <div className="back-section">
                <Link to="/afiliados">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
                </Link>
                <h6 className="card-title">Detalles de afiliado</h6>
            </div>
            <div className="row">
                <div className="col s12 m6">
                </div>
                <div className="col s12 m6">

                </div>
            </div>
            <div className="card">
                <div className="card-content">
                    <div className="avatar_content">
                        <img 
                            src={ afiliado ? afiliado.avatar : "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"} 
                            alt="Avatar"/>
                    </div>
                    <div className="tabs_content">
                        <br/>
                        <ul className="tabs tabs-fixed-width">
                            <li className="tab col s3">
                                <a className="active" href="#informacion">Informacion</a>
                            </li>
                            <li className="tab col s3">
                                <a href="#actividad">Actividad</a>
                            </li>
                            <li className="tab col s3">
                                <a className="active" href="#afiliados">Afiliados</a>
                            </li>
                        </ul>
                        <div id="actividad">
                            <Activity affcode={afiliado.affcode}/>
                        </div>
                        <div id="informacion">
                            <Info user={afiliado } id={id} />
                        </div>
                        <div id="afiliados">
                            <Red affcode={afiliado.affcode}/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default AfiliadoDetails;