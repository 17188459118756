import React, { useState, useEffect, useContext, useRef } from 'react'
import Course from '../components/Course'
import { fetchUser } from '../functions/authentication';
import { fetchCourses } from '../functions/firebasedb'
import {  AuthContext } from '../auth/AuthContext';

const Market = () => {
    const { user } = useContext(AuthContext);

    const [ courses, setCourses ] = useState({});
    const [ userx, setUserx ] = useState({});

    const mounted = useRef(false);

    useEffect(() => {
        fetchUser( setUserx,  user.email );
        fetchCourses( setCourses );
        console.log(user.info.affcode);
        return ()=>{}
    },[])
    // useEffect(() => {
    //     return () => {}
    // }, [])
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    })
    if(mounted.current){
        return (
    
            <div className="market_grid">
                { courses
                    && ( Object.entries(courses).map( course => {
                        return <Course 
                                course={course[1]} 
                                key={course[0]} 
                                admin={true} 
                                id={course[0]} 
                                affcode={ user.info.affcode} 
                                />
                    }))
                }
                
            </div>
        )
    }else{
        return <></>
    }
}

export default Market;