import React, { useState, useContext } from 'react'

import { AuthContext } from '../../auth/AuthContext';
import { createUser } from '../../functions/authentication';

import { Link } from 'react-router-dom';

const Register = ({history}) => {

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ phone, setPhone ] = useState("")
    const [ namex, setNamex ] = useState("")

    const { dispatch } = useContext(AuthContext)

    const handleSubmit = e => createUser(e, namex, email, phone, password,  history, dispatch);


    return (
        <div className="container login">
            <div className="row">
                <div className="show-on-large col s12 m5 ">
                    <div className="side_content">
                        <div>
                            <h5>Regístrate</h5>
                            <p>Ingresa tu información para formar parte del grupo de afiliados UNIVES</p>
                            <span>¿Ya tienes cuenta?,<Link to="/login"><strong className="link_outside"> inicia sesión aquí.</strong></Link></span>
                        </div>
                    </div>
                </div>
                <div className="col s12 m7 form-content">
                    <div>
                        <div className="card-content">
                            <div className="center-align">
                                <img src="https://unives.mx/wp-content/uploads/2021/01/logounives.png" />
                            </div>
                            <h5>Regístrate</h5>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Nombre completo</label>
                                    <input 
                                        type="text" 
                                        id="name" 
                                        placeholder="Tu nombre aquí"
                                        value={namex}
                                        onChange={e => setNamex(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Correo electrónico</label>
                                    <input 
                                        type="email" 
                                        id="email" 
                                        placeholder="example@unives.mx"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Teléfono</label>
                                    <input 
                                        type="text" 
                                        id="phone" 
                                        placeholder="Teléfono o celular"
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Contraseña</label>
                                    <input 
                                        type="password" 
                                        id="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        />
                                </div>
                                <div className="form-group center-align">
                                    <br/>
                                    <button className="btn btn-primary light-blue darken-4 waves-effect waves-light">Regístrate</button>
                                    <br/><br/>
                                    <span>¿Ya tienes cuenta?,<Link to="/login"><strong className="link_outside"> inicia sesión aquí.</strong></Link></span>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Register;