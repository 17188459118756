import { useState, useEffect } from 'react';
import { activeListener } from '../../functions/firebasedb';

export const Counter = ({ affcode }) => {

    const [ counter, setCounter ] = useState(0);

    useEffect(() => {
        
        activeListener(affcode, setCounter);

        return () => {}
    }, [ affcode ])

    return <span>{ counter }</span>
}
