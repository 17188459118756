import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { LessonItem } from '../../components/Fragments/LessonItem';
import { fetchLessons, verifyUser } from '../../functions/firebasedb';

const AcademyAdmin = () => {

    const { user } = useContext(AuthContext);
    const history = useHistory();

    const [lessons, setLessons] = useState({});

    const mounted = useRef(false)

    useEffect(() => {
        verifyUser( history, user.email );
        mounted.current = true;
        if(mounted.current){
            fetchLessons( setLessons, mounted.current );
        }
        return () => { mounted.current = false }
    }, [mounted])


    const renderItems = () => (
        lessons
        ? Object.entries(lessons).map(([id, value]) => <LessonItem key={id} id={id} lesson={value} />)
        : <p>Aún no hay contenido que mostrar</p>
    )

    return (
        <>
            <div className="row lesson-list">
                <div className="col m6 s12">
                    <h6 className="card-title">Lecciones de la Academia</h6>
                </div>
                <div className="col m6 s12 right-align">
                    <Link to="/academy/add">
                        <button className="btn blue darken-3">
                            + Agregar lección
                        </button>
                    </Link>
                </div>
            </div>
            <div>
                <div className="card">
                    <div className="card-content">
                        <ul className="collection">
                            { renderItems() }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AcademyAdmin;