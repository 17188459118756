import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchRed } from '../../functions/firebasedb'

 const Red = ({ affcode }) => {

    const [red, setRed] = useState({})
    useEffect(() => {
        fetchRed(affcode, setRed);
        return () => {}
    }, [affcode])

    return (
        <div>
            <br/>
            <table className="striped">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        red
                        ? Object.entries(red).map(([id, afiliado]) => (
                            <tr key={id}>
                                <td>{afiliado.affcode}</td>
                                <td>
                                    <Link to={`/afiliados/${id}/`}>
                                    {afiliado.name}
                                    </Link>
                                </td>
                                <td>
                                    <a href={`mailto:${afiliado.email}`}>
                                        { afiliado.email}
                                    </a>
                                </td>
                            </tr>
                        ))
                        : (
                            <tr>
                                <td>No se encontraron afiliados</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}
export default Red;