import React from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

export default function LayoutInside({ children }) {
    return (
        <div className="container-fluid">
            <Navbar/>
            <Sidebar/>
            <main>
                <div className="containerx">
                    { children}
                </div>
            </main>
            {/* <div className="containerx">
            </div> */}
        </div>
    )
}