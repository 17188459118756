import React, { useEffect, useState } from 'react'
import M from 'materialize-css'
import { updateCourse } from '../../functions/firebasedb';

export const ModalEdit = ({ edit, id }) => {

    const [ name, setName ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ landing, setLanding ] = useState("");

    const handleUpdate = e => {
        e.preventDefault();
        updateCourse(id, name, description, landing );
        document.getElementById('modal-close-1').click();
    }

    useEffect(() => {
        var elems3 = document.querySelectorAll('.modal');
        var instances3 = M.Modal.init(elems3, {});
        
        return () => {}
    }, [])
    useEffect(() => {
        if(edit){
            setName(edit.name)
            setDescription(edit.description)
            setLanding(edit.landing)
        }
        return ()=>{}
    },[edit]);
    return (
        <form onSubmit={ handleUpdate }>
            <div id="modalEdit" className="modal">
                <div className="modal-content">
                    <h5>Editar curso</h5>
                    <div className="row">
                        <div className="input-field col s12">
                            <input
                                placeholder="Nombre..."
                                id="nameE" type="text"
                                className="validate"
                                onChange={e => setName(e.target.value)}
                                defaultValue={name}
                                // defaultValue={edit.name}
                            />
                            <label htmlFor="nameE" className="active">Nombre del curso</label>
                        </div>
                        <div className="input-field col s12">
                            <input
                                placeholder="Descripción..."
                                id="descriptionE"
                                type="text"
                                className="validate"
                                onChange={e => setDescription(e.target.value)}
                                defaultValue={description}
                                // defaultValue={edit.description}
                            />
                            <label htmlFor="descriptionE" className="active">Descripción corta</label>
                        </div>
                        <div className="input-field col s12">
                            <input
                                placeholder="https://unives.mx/promocion-horizontal-docentes/"
                                id="landingE"
                                type="text"
                                className="validate"
                                onChange={e => setLanding(e.target.value)}
                                defaultValue={landing}
                                // defaultValue={edit.landing}
                            />
                            <label htmlFor="landingE" className="active">Liga de Landing</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <a className="btn mr-2 modal-close" id="modal-close-1" type="button">Cancelar</a>
                    <button className="btn blue darken-3" type="submit">Guardar cambios</button>
                </div>
            </div>
        </form>
    )
}
