import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="notfound_content">
            <h6>Página no encontrada</h6>
            <br/>
            <Link to="/">
                <button className="btn blue darken-3">Ir a la página principal</button>
            </Link>
        </div>
    )
}

export default NotFound;