import React, { useContext, useEffect, useState } from 'react'
import M from 'materialize-css';
import AfiliadoCard from '../components/AfiliadoCard';

import { AuthContext } from '../auth/AuthContext';
import { fetchRed } from '../functions/firebasedb';

const Network = () => {

    const { user } = useContext(AuthContext);

    const [ red, setRed ] = useState({});

    const handleClick = () => {
        var inp = document.getElementById('linkinvite');
        inp.select();
        inp.setSelectionRange(0, 99999);
        document.execCommand("copy");
        M.toast({ html: 'Link copiado al portapapeles' })
    }
    const handleClickLanding = () => {
        var inp = document.getElementById('linklanding');
        inp.select();
        inp.setSelectionRange(0, 99999);
        document.execCommand("copy");
        M.toast({ html: 'Link copiado al portapapeles' })
    }
    useEffect(() => {
        fetchRed( user.info.affcode, setRed)
        return () => {}
    }, [])
    return (
        <div>
            <h6>Mi red de afiliados</h6>
            <div className="card">
                <div className="card-content">
                    <label htmlFor="linkinvite">Link para registro directo</label>
                    <div className="link_content">
                        <input type="text" id="linkinvite" value={`https://afiliados.unives.mx/register/${user.info.affcode}/`} readOnly/>
                        <button className="btn indigo accent-1 copy" onClick={ handleClick }> 
                            <i className="material-icons">content_copy</i>
                            <span>Copiar</span>
                        </button>
                    </div>
                    <label htmlFor="linklanding">Link de Landing</label>
                    <div className="link_content">
                        <input type="text" id="linklanding" value={`https://landings.unives.mx/afiliados-invitacion/?affcode=${user.info.affcode}`} readOnly/>
                        <button className="btn indigo accent-1 copy" onClick={ handleClickLanding }> 
                            <i className="material-icons">content_copy</i>
                            <span>Copiar</span>
                        </button>
                    </div>
                    <br/>
                    <h6>Afiliados</h6>
                    <div className="row">
                        { red 
                            ? (
                                Object.entries(red).map(afiliado => <AfiliadoCard key={afiliado[0]} afiliado={afiliado[1]}/>)
                            )
                            : (
                                <div className="col s12">
                                    <span>Aún no tienes afiliados</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Network;