import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { fetchCourse, updateCourse, verifyUser } from '../../functions/firebasedb';

const EditCourse = () => {

    const { user } = useContext(AuthContext);
    const history = useHistory();

    const { id } = useParams();
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [landing, setLanding] = useState("")

    const handleSubmit = e => updateCourse( e, id, name, description, landing );
    
    useEffect(() => {
        verifyUser( history, user.email );
        fetchCourse( id, setName, setDescription, setLanding )
        return () => {}
    }, [])

    return (
        <div>
            <h6 className="card-title">
                <Link to="/courses/">Cursos</Link> 
                <i className="material-icons">keyboard_arrow_right</i>
                Editar curso
            </h6>
            <div className="card">
                <div className="card-content">
                <form onSubmit={handleSubmit}>
                    <div className="modal-content">
                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    placeholder="Nombre..."
                                    id="name" type="text"
                                    className="validate"
                                    value={ name }
                                    onChange={e => setName(e.target.value)}
                                />
                                <label htmlFor="name" className="active">Nombre del curso</label>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    placeholder="Descripción..."
                                    id="description"
                                    type="text"
                                    className="validate"
                                    value={ description }
                                    onChange={e => setDescription(e.target.value)}
                                />
                                <label htmlFor="description" className="active">Descripción corta</label>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    placeholder="https://unives.mx/promocion-horizontal-docentes/"
                                    id="landing"
                                    type="text"
                                    className="validate"
                                    value={ landing }
                                    onChange={e => setLanding(e.target.value)}
                                />
                                <label htmlFor="landing" className="active">Liga de Landing</label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Link className="btn mr-2 modal-close" id="modal-close" type="button" to="/courses/">Cancelar</Link>
                        <button className="btn blue darken-3" type="submit">Actualizar curso</button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    )
}

export default EditCourse;