import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';
import ContactItem from '../components/Fragments/ContactItem';
import { getMyContacts } from '../functions/firebasedb';


const Contacts = () => {

    const [contacts, setContacts] = useState({});
    const { user } = useContext(AuthContext);
    
    useEffect(() => {
        getMyContacts( user.info.affcode, setContacts);
    }, [user])

    return (
        <>
        <div className="row">
            <div className="col s6">
                <h6 className="card-title">Mis Contactos</h6>
            </div>
            <div className="col s6 align-right">
                <button className="btn btn-secondary" onClick={ () => getMyContacts(user.info.affcode, setContacts ) }>
                    <i className="material-icons">sync</i>
                </button>
            </div>
        </div>
            <div className="card card">
                <div className="card-content">
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Contacto</th>
                                <th>Fecha</th>
                                <th>Programa Educ.</th>
                            </tr>
                        </thead>
                        <tbody>
                            { contacts && Object.entries(contacts).map( contact => {
                                if(contact[1].affcode!== "N/A"){
                                    return(
                                        <ContactItem key={contact[0]} contact={contact[1]} contactId={contact[0]}/>
                                    )
                                }
                            })}
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}


export default Contacts;