import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Afiliado } from './Afiliado';
import { fetchAfiliados, verifyUser } from '../../functions/firebasedb';

import { AuthContext } from '../../auth/AuthContext';


 const Afiliados = () => {

    const [ afiliados, setAfiliados ] = useState({})
    const history = useHistory();

    const { user } = useContext(AuthContext);

    useEffect(() => {
        fetchAfiliados( setAfiliados )
        verifyUser( history, user.email );
        return () => {}
    }, [])

    return (
        <div>
            <h6 className="card-title">Lista de afiliados</h6>
            <div className="card">
                <div className="card-content">
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Contacto</th>
                                <th className="actions">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { afiliados

                                && (Object.entries(afiliados).map( afiliado => (
                                    <Afiliado key={afiliado[0]} afiliado={afiliado[1]} id={afiliado[0]} affcode={afiliado[1].affcode}/>
                                )))

                            }
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    )
}

export default Afiliados;