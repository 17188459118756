import firebase from '../constants/firebase';
import M from 'materialize-css';
const dbRef = firebase.database();
const stgRef = firebase.storage();


export const activeListener = ( id, setCounter ) => {
    dbRef.ref('/people').orderByChild('affcode').equalTo(id).once( 'value', snap => {
        if(snap.val()){
            setCounter(snap.numChildren());
        }else{
            setCounter(0);
        }
    })
}

export const getRedCounter = ( affcode, setCounter ) => {
    if(affcode){
        dbRef.ref('users').orderByChild('affiliateBy').equalTo(affcode).once('value', snap => {
            if(snap.val()){
                setCounter(snap.numChildren());
            }else{
                setCounter(0);
            }
        })
    }
}

// CURSOS

export const addCourse = ( name, description, landing) => {
    if(name, description, landing){
        try{
    
            dbRef.ref('courses').push({
                name, description, landing,
                photo: "https://unives.mx/promocion-horizontal-docentes/"
            });
    
            M.toast({ html: 'Curso agregado correctamente' });
        }catch(err){
            console.log(err);
            M.toast({ html: 'Error al agregar'});
        }
    }else{
        M.toast({ html: 'Rellena los campos'})
    }
}

export const fetchCourses = ( setCourses ) => {
    const courseRef = dbRef.ref('courses');
    courseRef.on('value', snap => {
        if(snap.val()){
            setCourses(snap.val())
        }else{
            M.toast({ html: 'No se han encontrado cursos' });
        }
    });
}

/* export const updateCourse = ( id, name, description, landing ) => {

    if(id, name, description, landing){
        try{
            const ref = dbRef.ref(`courses/${id}`).update({
                name, description, landing
            });
            M.toast({ html: 'Actualizado correctamente' });
        }catch(err){
            M.toast({ html: 'Error al actualizar' });
            console.log(err);
        }
    }else{
        M.toast({ html: 'Rellena los campos.' })
    }
} */

export const deleteCourse = ( id ) => {
    try{
        const ref = dbRef.ref(`courses/${id}`).remove();
        M.toast({ html: 'Curso eliminado correctamente' });
    }catch(err){
        M.toast({ html: 'Error al eliminar' });
        console.log(err);

    }

}

export const changeCoursePhoto = ( e, id ) => {
    if(e.target.files[0]){
        M.toast({ html: 'Subiendo archivo, espera un momento' });
        var uploadTask = stgRef.ref(`/courses/${e.target.files[0].name}`).put(e.target.files[0]);

        uploadTask.on('state_changed', function(snapshot){

        }, function(err){
            M.toast({ html: 'Error al subir el archivo'});
        }, function(){
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
                dbRef.ref(`courses/${id}`).update({
                    photo: downloadURL
                });
                M.toast({ html: 'Imagen actualizada'});
                e.target.value = "";
            })
        })
    }
}

export const fetchAfiliados = ( setAfiliados ) => {
    try{
        const ref = dbRef.ref('users');
        //const ref = dbRef.ref('users').orderByChild('admin').equalTo(0);
        ref.on('value', snap => {
            if(snap.val()){
                setAfiliados( snap.val())
            }else{
                M.toast({ html: 'No se encontraron afiliados '});
            }
        })
    }catch(err){
        console.log(err);
    }
}

export const verifyUser = ( history, email) => {
    try{
        dbRef.ref('users').orderByChild('email').equalTo(`${email}`).once('value', snap => {
            if(snap.val()){
                if(Object.entries(snap.val())[0][1].admin == 1){
                    return true;
                }else{
                    history.push('/')
                }
            }else{
                history.push('/')
            }
        })
    }catch(err){
        console.log(err)
    }
}

export const updateProfile = ( id, name, phone, bankNumber, state, bankName ) => {
    !name && M.toast({ html: 'Rellena el campo Nombre'});
    !phone && M.toast({ html: 'Rellena el campo Teléfono'});
    !bankNumber && M.toast({ html: 'Rellena el campo Cuenta bancaria'});
    !state && M.toast({ html: 'Rellena el campo Estado/Provincia'});
    
    if( name && phone && state && bankNumber && id){
        const updated = dbRef.ref(`users/${id}`).update({
            name, phone, bank : bankNumber, state, bankName
        });
        if(updated){
            M.toast({ html: 'Perfil actualizado' })
        }else{
            M.toast({ html: 'Error al actualizar'})
        }

    }
}

export const changeAvatar = ( e, id ) => {
    if(e.target.files[0] && id){
        M.toast({ html: 'Subiendo imagen, espera un momento' });
        var uploadTask = stgRef.ref(`/users/${e.target.files[0].name}`).put(e.target.files[0]);

        uploadTask.on('state_changed', function(snapshot){

        }, function(err){
            M.toast({ html: 'Error al subir el archivo'});
        }, function(){
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
                dbRef.ref(`users/${id}`).update({
                    avatar: downloadURL
                });
                // setString(Math.random()*10);
                M.toast({ html: 'Imagen actualizada'});
                e.target.value = "";
            })
        })
    }
}

export const fetchRed = ( affcode, setRed ) => {
    dbRef.ref('users').orderByChild('affiliateBy').equalTo(`${affcode}`).once('value', snap => {
        if(snap.val()){
            setRed(snap.val())
        }
    })
}

export const addLesson = ( e, title, link, description, history ) => {
    e.preventDefault();

    if(title && link && description ){
        const lesson = dbRef.ref('lessons').push({
            title, link, description
        });
        if(lesson){
            M.toast({ html: 'Lección agregada correctamente'});
            history.push('/academy/admin/');
        }else{
            M.toast({ html: 'Error al agregar' });
        }
    }else{
        M.toast({ html: 'Rellena los campos'});
    }

}

export const fetchLessons = ( setLessons, mounted = true ) => {
    const listener = dbRef.ref('lessons').on('value', snap => {
        if(snap.val() && mounted){
            setLessons(snap.val());
        }
    });
    //dbRef.off("value", listener);
}

export const fetchFirstLesson = ( setSelected ) => {
    dbRef.ref('lessons').limitToFirst(1).once('value', snap => {
        if(snap.val()){
            //console.log('FIRST >>', Object.entries(snap.val())[0][1]);
            setSelected(Object.entries(snap.val())[0][1])
        }
    })
}

export const deleteLesson = ( id ) => {
    dbRef.ref(`lessons/${id}`).remove() && M.toast({ html: 'Eliminado correctamente'});    
}

export const updateLesson = (e, id, title, link, description) => {
    e.preventDefault();
    if(title && link && description){
        const uped = dbRef.ref(`lessons/${id}/`).update({
            title, link, description
        });
        if(uped){
            M.toast({ html: 'Actualizado correctamente'})
        }else{
            M.toast({ html: 'Error al actualizar'});
        }
    }else{
        M.toast({ html: 'Rellena los campos '});
    }
}

export const fetchLesson = async ( id, setTitle, setLink, setDescription ) =>{
    dbRef.ref(`lessons/${id}/`).once('value', snap => {
        if(snap.val()){
            const { title, link, description } = snap.val();
            setTitle(title);
            setLink(link);
            setDescription(description);
        }
    });
}

export const fetchCourse = ( id , setName, setDescription, setLanding) => {
    dbRef.ref(`/courses/${id}/`).on('value', snap => {
        if(snap.val()){
            const { name, description, landing } = snap.val();
            setName(name);
            setDescription(description);
            setLanding(landing);
        }
    })
}

export const updateCourse = ( e, id, name, description, landing ) => {
    e.preventDefault();
    if( name, description, landing ){
        const updated = dbRef.ref(`/courses/${id}/`).update({
            name, description, landing
        });
        if(updated){
            M.toast({ html: "Actualizado correctamente" });
        }else{
            M.toast({ html: "Error al actualizar" })
        }
    }else{
        M.toast({ html: 'Rellena los campos'});
    }
    
}

export const fetchAfiliado = ( id, setAfiliado ) => {
    try{
        const ref = dbRef.ref(`users/${id}`).on('value', snap => {
            if(snap.val()){
                setAfiliado(snap.val());
            }
        });
    }catch(err){
        console.log(err);
    }
}

export const getAfiliadoPor = async ( affid ) => {

    
    var output = "N/A";
    if(affid){
        await dbRef.ref('users').orderByChild('affcode').equalTo(affid).once('value', (snap) => {
            if(snap.val()){
                console.log(snap.val());
                output = Object.entries(snap.val())[0][1].name;
            }
        })

    }else{
        console.log("N/A");
    }
    return output;
}
export const updateStatusClientifyAfiliado = ( id, url ) => {
    try{
        dbRef.ref(`users/${id}/`).update({
            clientify: url
        });
    }catch(err){
        console.log(err)
    }
}

export const getMyContacts = async ( affcode, setContacts ) => {

    if(!affcode){
        console.log('Before => ');
        return 0;
        console.log('After => ');
    }
    await dbRef.ref(`/people`).orderByChild('affcode').equalTo(affcode).once('value', snap => {
        if(snap.val()){
            setContacts(snap.val());
            M.toast({ html: 'Lista actualizada.'})
        }
    });
}

export const getAllContacts = async (setContacts) => {

    let contacts = [];

    dbRef.ref(`/people`).on('value', snap => {
        if(snap.val()){
            
            setContacts(snap.val())
            // for(let person in snap.val()){
                // if(snap.val()[person].affcode !== "N/A"){
                //     // console.log('Person => ', snap.val()[person]);
                //     // contacts.push({id: [person][0], contact: snap.val()[person]})
                // }
            // }
            // console.log('Error SNAP -> ', snap.val())
            // setContacts(contacts);
        }
    });

    console.log(contacts);
}

export const getContact = async ( contactId, setContact ) => {
    dbRef.ref(`/people/${contactId}/`).on('value', snap => {
        if(snap.val()){
            setContact(snap.val())
        }else{
            M.toast({html: 'Contacto No encontrado'})
        }
    })
}

export const removeContact = async (contactId) => {
    console.log(contactId);
    const resRemove = dbRef.ref(`/people/${contactId}`).remove();
    if (resRemove){
        M.toast({html: 'Eliminado correctamente'});
    }else{
        M.toast({html: 'Error al eliminar'});
    }
}

export const addResults = ( result, status, id) => {
    if(result && status && id){
        try {
            dbRef.ref(`/people/${id}`).update({
                venta: status,
                resultado: result,
            });
            M.toast({ html : 'Actualizado Correctamente'});
        } catch (error) {
            console.log(error)
            M.toast({ html: 'Error al guardar cambios'});
        }
    }
}

export const getAfiliadoInfo = async ( affid, setAfiliado ) => {

    
    var output = {
        id: '',
        name: ''
    };
    if(affid){
        await dbRef.ref('users').orderByChild('affcode').equalTo(affid).once('value', (snap) => {
            if(snap.val()){
                setAfiliado({
                    id: Object.entries(snap.val())[0][0],
                    name: Object.entries(snap.val())[0][1].name
                });
            }
        })

    }else{
        console.log("N/A");
    }
    return output;
}