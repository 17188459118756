import React from 'react'
import { Link } from 'react-router-dom';
import { deleteLesson } from '../../functions/firebasedb'

export const LessonItem = ({ lesson, id }) => {

    const handleDelete = () => {
        if(window.confirm(`¿Estás seguro de eliminar ${ lesson.title }?`)){
            deleteLesson( id );
        }
    }


    return (
        <li className="collection-item avatar">
            <i className="material-icons circle red">play_arrow</i>
            <span className="title">{ lesson.title }</span>
            <p>
                <a href={lesson.link} target="_blank" rel="noopener noreferrer">
                    { lesson.link }
                </a>
            </p>
            <div href="#!" className="secondary-content lesson_actions">
                <i className="material-icons delete" onClick={ handleDelete }>delete</i>
                <Link to={`/academy/${id}/edit/`}>
                    <i className="material-icons edit">edit</i>
                </Link>
            </div>
        </li>
    )
}
