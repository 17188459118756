import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { fetchLesson, updateLesson } from '../../functions/firebasedb';

const EditLesson = () => {

    const [title, setTitle] = useState("")
    const [link, setLink] = useState("")
    const [description, setDescription] = useState("")

    const { id } = useParams();
    const mounted = useRef(false)

    const handleSubmit = e => updateLesson(e, id, title, link, description );

    useEffect(() => {
        mounted.current = true;
        if(mounted.current){
            fetchLesson( id, setTitle, setLink, setDescription );
        }
        return () => {
            mounted.current = false
        }
    }, [])

    return (
        <div>
            <h6 className="card-title">
                <Link to="/academy/admin/">Academia Admin</Link> 
                <i className="material-icons">keyboard_arrow_right</i>
                Editar lección
            </h6>
            <div className="card">
                <div className="card-content contact_content">
                <form onSubmit={ handleSubmit }>
                        <div className="row">
                            <div className="col s12 input-field">
                                <input
                                    type="text"
                                    name="title" 
                                    id="title"
                                    value={ title }
                                    onChange={ e => setTitle(e.target.value)}
                                    //defaultValue={ lesson ? lesson.title : "no"}
                                    />
                                <label htmlFor="title" className="active">Título de la lección</label>
                            </div>
                            <div className="col s12 input-field">
                                <input
                                    type="text"
                                    name="video" 
                                    id="video"
                                    value={ link }
                                    onChange={ e => setLink(e.target.value)}
                                    />
                                <label htmlFor="video" className="active">Link de video (Youtube)</label>
                            </div>
                            <div className="col s12 input-field">
                                <textarea 
                                    name="description" 
                                    id="description" 
                                    className="materialize-textarea" 
                                    cols="30" 
                                    rows="10"
                                    value={ description }
                                    onChange={e => setDescription(e.target.value)}
                                    ></textarea>
                                <label htmlFor="description" className="active">Descripción</label>
                            </div>
                            <div className="col s12 right-align">
                                <Link to="/academy/admin/" className="btn gray darken-3 mr-2">
                                    Cancelar
                                </Link>
                                <button type="submit" className="btn blue darken-3">Actualizar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditLesson;