import React, { useEffect, useState } from 'react'
import { getRedCounter } from '../../functions/firebasedb';

export const RedCounter = ({ affcode }) => {

    const [counter, setCounter] = useState(0)

    useEffect(() => {
        getRedCounter( affcode, setCounter );
        return () => {}
    }, [affcode])

    return <span>{ counter }</span>
}
