import React from 'react'
import M from 'materialize-css';

export const ModalLinks = ({ id, landing, affcode }) => {

    const handleClick = () => {
        var hb = document.getElementById(`btn-${id}`);
        hb.select();
        hb.setSelectionRange(0, 99999);
        document.execCommand("copy");
        M.toast({ html: 'Link copiado al portapapeles' });
    }
    return (
        <div id={`dpl-${id}`} className="modal">
            <div className="modal-content">
                <h5>Mis links</h5>
                <div className="row">
                    <div className="col s12">
                        <input 
                            type="text" 
                            value={`${landing}?affcode=${affcode}`} 
                            id={`btn-${id}`}
                            readOnly
                            />
                    </div>
                    <div className="col s12">
                        <button className="btn indigo accent-1 copy" onClick={ handleClick }> 
                            <i className="material-icons">content_copy</i>
                            <span>Copiar</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a className="btn mr-2 modal-close" type="button">Cerrar</a>
            </div>
        </div>
    )
}
