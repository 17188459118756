import React from 'react'
import { Link } from 'react-router-dom';
import { Counter } from '../../components/Fragments/Counter';

export const Afiliado = ({ afiliado, affcode, id }) => {

    return (
        <tr>
            <td>
                <Link to={`/afiliados/${id}/`}>
                    <span className={`status ${afiliado.clientify ? "success": "danger"}`}></span>
                    {afiliado.name }
                </Link>
            </td>
            <td>
                <a href={`mailto:${afiliado.email}`}>{ afiliado.email}</a>
                <a href={`tel:${afiliado.phone}`}>{ afiliado.phone }</a>
            </td>
            <td>
                { afiliado.affcode}
            </td>
        </tr>
    )
}
