import M from 'materialize-css';
import firebase from '../constants/firebase';
import { types } from '../types/types';
import { sendTo } from './clientify';

const dbRef = firebase.database();

export const SignIn = (e, email, password, history, dispatch) => {
    e.preventDefault();

    firebase.auth().signInWithEmailAndPassword(email, password)
        .then(user => {
            history.push('/');
            fetchUserLoaded( dispatch, user.user.email)
        })
        .catch(err => {
            M.toast({html: 'Usuario/contraseña incorrectos'});
        });
}

export const LogOut = ( dispatch, history ) => {
    dispatch({
        type: types.logout,
        payload: {
            email: null
        }
    });

    firebase.auth().signOut().then(() => {
        M.toast({html: 'Has cerrado sesión correctamente'});
    }).catch(err => {
        console.log('An error happened');
    })
    history.replace('/login');
}

// Registro

export const createUser = ( e, name, email, phone, password, history, dispatch) => {
    e.preventDefault();

    var afff = localStorage.getItem('affcode') || "N/A";

    if(name && email && phone && password ){
        firebase.auth().createUserWithEmailAndPassword( email, password )
            .then((user) => {
                var time = new Date().getTime().toString();

                const ref = dbRef.ref('users').push({
                    email, name, phone,
                    avatar: "https://electronicssoftware.net/wp-content/uploads/user.png",
                    affcode: time,
                    affiliateBy: afff,
                    admin: 0
                });
                if(ref){
                    M.toast({ html: 'Te has registrado exitosamente'});
                    fetchUserLoaded( dispatch, email)
                }
                history.push('/');
            }).catch((err) => {
                M.toast({ html: 'Error al registrarte'})
            });
    }else{
        M.toast({ html: 'Rellena los campos e intenta nuevamente'});
    }
}

export const fetchUser = ( setUserx, email ) => {
    dbRef.ref(`users`).orderByChild('email').equalTo(email).on('value', (snap) => {
        if(snap.val()){
             setUserx({
                user: Object.entries(snap.val())[0][1],
                id: Object.entries(snap.val())[0][0],
            })
        }else{
           console.log('No se encontró al usuario') 
        }
    })
}

export const fetchUserLoaded = ( dispatch, email ) => {
    dbRef.ref(`users`).orderByChild('email').equalTo(email).once('value', (snap) => {
        if(snap.val()){
            sendTo(Object.entries(snap.val())[0][1], Object.entries(snap.val())[0][0]);
            dispatch({
                type: types.login,
                payload: {
                    email: email,
                    info : {
                        user: Object.entries(snap.val())[0][1],
                        id: Object.entries(snap.val())[0][0],
                        affcode: Object.entries(snap.val())[0][1].affcode
                    }
                }
            })
        }else{
           console.log('No se encontró al usuario') 
        }
    })
}

export const forgotPassword = (e, email, setEmail) => {
    e.preventDefault();
    if(email){
        try{
            firebase.auth().sendPasswordResetEmail(email). then(() => {
                setEmail("");
                M.toast({ html: "Correo enviado correctamente"})
            })
        }catch(err){
            M.toast({ html: "Ingresa un correo válido"})
            console.log(err)
        }
    }else{
        M.toast({ html: 'Ingresa un email válido'})
    }
}