import React from 'react'
import { sendAfiliadoToClientify } from '../functions/clientify';

const Testing = () => {

    const handleClick = e => sendAfiliadoToClientify();

    return (
        <div>
            <h6 className="card-title">Testing</h6>
            <div className="card">
                <div className="card-content">
                    <button 
                    className="btn blue darken-3"
                    onClick={ handleClick }
                    >Fetch data</button>
                </div>
            </div>
        </div>
    )
}

export default Testing;