import React, { useEffect, useState } from 'react'
import { formatLinkVideo } from '../../functions/utils';

export const Video = ({ link }) => {
    const [readyLink, setReadyLink] = useState("")

    useEffect(() => {
        formatLinkVideo( link, setReadyLink );
        return () => {}
    }, [link])

    return (
        <div>
            <iframe 
                width="100%" 
                height="400" 
                // src={`https://www.youtube.com/embed/${link}`}
                src={`https://www.youtube.com/embed/${readyLink}`}
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
        </div>
    )
}
