import React from 'react'

const AfiliadoCard = ({ afiliado }) => {
    return (
        <div className="col s12 m4 l3">
            <div className="card">
                <div className="card-content center-align">
                    <div className="avatar_content">
                        <img src={ afiliado.avatar } alt="Avatar"/>
                    </div>
                    <h6>{ afiliado.name }</h6>
                </div>
            </div>
        </div>
    )
}

export default AfiliadoCard;