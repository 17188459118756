import React,{ useContext } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';

import { LogOut } from '../functions/authentication';

export const Sidebar = () => {
    const { dispatch, user } = useContext(AuthContext);
    const history = useHistory()
    const handleLogout =  () => LogOut(dispatch, history);

    return (
        <ul id="slide-out" className="sidenav sidenav-fixed">
            <li>
                <NavLink
                    to="/"
                    exact
                    activeclassname="active"
                >
                    <i className="material-icons">apps</i>
                    <span>Market</span>
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/red"
                    activeclassname="active"
                >
                    <i className="material-icons">people</i>
                    <span>Mi red</span>
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/academy"
                    activeclassname="active"
                    exact
                >
                    <i className="material-icons">school</i>
                    <span>Academia</span>
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/contacts"
                    activeclassname="active"
                    exact
                >
                    <i className="material-icons">person_pin</i>
                    <span>Contactos</span>
                </NavLink>
            </li>
            <li>
                <a
                    href="https://drive.google.com/drive/folders/1YydKt6oqwnNjerkxJvtw9WOpdfUDOngA?usp=sharing"
                    target="_blank"
                    rel="noopener nofollow"
                >
                    <i className="material-icons">perm_media</i>
                    <span>Material</span>
                </a>
            </li>
            {
                user.info.user.admin == 1
                && (
                    <>
                        <li><div className="divider"></div></li>
                        <li>
                            <a href="" className="subheader">Administrador</a>
                        </li>
                        <li>
                            <NavLink to="/courses" activeclassname="active">
                                <i className="material-icons">class</i>
                                <span>Cursos</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/afiliados" activeclassname="active">
                                <i className="material-icons">person_add</i>
                                <span>Afiliados</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/contacts" activeclassname="active">
                                <i className="material-icons">person_pin</i>
                                <span>Contactos</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/academy/admin" activeclassname="active">
                                <i className="material-icons">school</i>
                                <span>Academia Admin</span>
                            </NavLink>
                        </li>
                    </>
                )
            }
            <li><div className="divider"></div></li>
            <li>
                <a href="" className="subheader">
                    
                    <span>Mi cuenta</span>
                </a>
            </li>
            <li>
                <NavLink to="/profile" activeclassname="active">
                    <i className="material-icons">person</i>
                    <span>Mi perfil</span>
                </NavLink>
            </li>
            <li onClick={ handleLogout }>
                <a href="">
                    <i className="material-icons">exit_to_app</i>
                    <span>Cerrar sesión</span>
                </a>
            </li>            
        </ul>
    )
}

export default Sidebar;