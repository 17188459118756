import React, { useEffect } from 'react'
import M from 'materialize-css';
import { deleteCourse } from '../functions/firebasedb';
import { ImageCourse } from './Fragments/ImageCourse';
import { Link } from 'react-router-dom';

const CourseAdmin = ({ course, admin=false, id, setInfo }) => {

  const handleDelete = () => {
    const ans = window.confirm(`¿Estás seguro de eliminar "${course.name}?"`);
    if(ans){
      deleteCourse( id )
    }else{
      console.log('NOT');
    }
  }

  useEffect(() => {
    var elems2 = document.querySelectorAll('.dropdown-trigger');
    var instances2 = M.Dropdown.init(elems2, {});
    return () => {}
  }, [])

    return (
        <div className="card_course">
            <div className="card">
              <ImageCourse image={course.photo} id={id}/>
              <div className="card-content">
                <h6 className="card-title">{ course.name } 
                <ul id={`dp-${id}`} className='dropdown-content'>
                  <li>
                    
                    <Link to={`/course/${id}/edit`}>
                      <span>Editar</span>
                    </Link>
                  </li>
                  <li><a onClick={ handleDelete }>Eliminar</a></li>
                </ul>
                  <a className="dropdown-trigger" data-target={`dp-${id}`}>
                    <i className="material-icons right " >more_vert</i>
                  </a>
                </h6>
                
                <p>{ course.description}</p>
              </div>
            </div>
        </div>
    )
}

export default CourseAdmin;