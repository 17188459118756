import React, { useContext, useEffect } from 'react';
import M from 'materialize-css';
import { Link, useHistory } from 'react-router-dom';

import { AuthContext } from '../auth/AuthContext';
import { LogOut } from '../functions/authentication';

const Navbar = () => {
    const { dispatch, user } = useContext(AuthContext);

    const history = useHistory()
    const handleLogout = () => LogOut(dispatch, history);

    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        var instances = M.Sidenav.init(elems, {});
        return () => { }
    }, [])

    return (
        <div>
            <ul id="dropdown1" className="dropdown-content">
                <li>
                    <Link to="/profile">
                        <i className="material-icons">perm_identity</i>Mi perfil
                    </Link>
                </li>
                <li>
                    <a onClick={handleLogout} href="#!">
                        <i className="material-icons">exit_to_app</i>
                        Cerrar sesión
                    </a>
                </li>
            </ul>
            <nav className="navbar light-blue darken-4">
                <div className="nav-wrapper">
                    <Link to="/" className="brand-logo">Afiliados</Link>
                    <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                    <ul className="right hide-on-med-and-down">

                        {/* <li>
                                <a className="dropdown-trigger" data-target="dropdown1">
                                    Mi cuenta<i className="material-icons right">arrow_drop_down</i>
                                </a>
                            </li> */}
                    </ul>
                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo">
                <li>
                    <Link to="/"
                    >
                        <i className="material-icons">apps</i>Market
                    </Link>
                </li>
                <li>
                    <Link to="/red"
                    >
                        <i className="material-icons">people</i>Mi red
                    </Link>
                </li>
                <li>
                    <Link
                        to="/academy"
                        
                    >
                        <i className="material-icons">school</i> Academia
                    </Link>
                </li>
                <li>
                    <Link
                        to="/contacts"
                        activeclassname="active"
                        
                    >
                        <i className="material-icons">person_pin</i>
                        <span>Contactos</span>
                    </Link>
                </li>
                <li>
                    <a
                        href="https://drive.google.com/drive/folders/1YydKt6oqwnNjerkxJvtw9WOpdfUDOngA?usp=sharing"
                        target="_blank"
                        rel="noopener nofollow"
                        data-position="bottom"
                        className="tooltipped"
                        data-tooltip="Material de divulgación"
                    >
                        <i className="material-icons">perm_media</i>
                        Material
                    </a>
                </li>
                <li>
                    <Link to="/profile"
                        
                        >
                        <i className="material-icons">perm_identity</i>Mi perfil
                    </Link>
                </li>
                {
                    user.info.user.admin == 1
                    && (
                        <>
                            <li><div className="divider"></div></li>
                            <li>
                                <a href="" className="subheader">Administrador</a>
                            </li>
                            <li>
                                <Link  to="/courses">
                                    <i className="material-icons">class</i>
                                    <span>Cursos</span>
                                </Link>
                            </li>
                            <li>
                                <Link  to="/afiliados">
                                    <i className="material-icons">person_add</i>
                                    <span>Afiliados</span>
                                </Link>
                            </li>
                            <li>
                                <Link  to="/admin/contacts" activeclassname="active">
                                    <i className="material-icons">person_pin</i>
                                    <span>Contactos</span>
                                </Link>
                            </li>
                            <li>
                                <Link  to="/academy/admin/">
                                    <i className="material-icons">school</i>
                                    <span>Academia Admin</span>
                                </Link>
                            </li>
                        </>
                    )
                }
                <li>
                    <a onClick={handleLogout}>
                        <i className="material-icons">exit_to_app</i>Cerrar sesión
                    </a>
                </li>
            </ul>
        </div>
    )
}
export default Navbar;
