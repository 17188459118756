import React, { useContext } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { AuthContext } from '../auth/AuthContext'

import Login from '../pages/affiliate/Login';
import Register from '../pages/affiliate/Register';
import Tablero from '../pages/Tablero';
import Market from '../pages/Market';
import Profile from '../pages/Profile';
import Network from '../pages/Network';
import Courses from '../pages/admin/Courses';
import Afiliados from '../pages/admin/Afiliados';
import NotFound from '../components/NotFound';
import RegisterInvite from '../pages/affiliate/RegisterInvite';
import Academy from '../pages/Academy';
import AddLesson from '../pages/admin/AddLesson';
import AcademyAdmin from '../pages/admin/AcademyAdmin';
import EditLesson from '../pages/admin/EditLesson';
import EditCourse from '../pages/admin/EditCourse';
import AfiliadoDetails from '../pages/admin/AfiliadoDetails';
import Testing from '../pages/Testing';
import ForgotPassword from '../pages/ForgotPassword';
import Contacts from '../pages/Contacts';
import ContactDetails from '../pages/ContactDetails';
import ContactsAdmin from '../pages/admin/ContactsAdmin';

const Navigation = () => {

    const { user } = useContext(AuthContext)
    return (
        <BrowserRouter>
            <Switch>
                {/* <PrivateRoute exact path="/" component={ Home } isAuthenticated={ user.logged } /> */}
                {/* <PrivateRoute exact path="/" component={ Tablero } isAuthenticated={ user.logged } /> */}
                <PrivateRoute exact path="/" component={ Market } isAuthenticated={ user.logged } />
                <PublicRoute exact path="/login" component={ Login } isAuthenticated={ user.logged } />
                <PublicRoute exact path="/register" component={ Register } isAuthenticated={ user.logged } />
                <PublicRoute exact path="/register/:affcode/" component={ RegisterInvite } isAuthenticated={ user.logged } />
                <PublicRoute exact path="/forgot-password" component={ ForgotPassword } isAuthenticated={ user.logged }/>
                <PrivateRoute exact path="/profile" component={ Profile } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/courses" component={ Courses } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/red" component={ Network } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/academy" component={ Academy } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/contacts" component={ Contacts } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/contacts/:contactId/details/" component={ContactDetails} isAuthenticated={ user.logged}/>
                {/* admin routes */}
                <PrivateRoute exact path="/testing" component={Testing} isAuthenticated={ user.logged }/>
                <PrivateRoute exact path="/afiliados" component={ Afiliados } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/afiliados/:id/" component={AfiliadoDetails} isAuthenticated={ user.logged }/>
                <PrivateRoute exact path="/course/:id/edit" component={ EditCourse } isAuthenticated={ user.logged }/>
                <PrivateRoute exact path="/academy/add" component={ AddLesson } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/academy/admin/" component={ AcademyAdmin } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/academy/:id/edit/" component={ EditLesson } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/admin/contacts/" component={ ContactsAdmin } isAuthenticated={user.logged} />
                <PublicRoute path="*" component={ NotFound } isAuthenticated={ user.logged }/>
            </Switch>
        </BrowserRouter>
    )
}

export default Navigation;